const Slider = (function () {

    function searchForSliders() {
        const sliders = document.getElementsByClassName('slider');
        for (let i = 0; i < sliders.length; i++) {
            const settings = JSON.parse(sliders[i].dataset.settings);
            initializeSlider(sliders[i].classList[0], settings);
        }
    }

    function initializeSlider(sliderClass, settings) {
        $(`.${sliderClass}`).slick(settings);
    }

    function init() {
        searchForSliders();
    }

    return {
        init
    }

})();

Slider.init();